/* MENU: SLIDE MENU
-------------------------------------------- */

.ptf-slide-menu {
	position: fixed;
	top: 0;
	left: px2rem(80px);
	z-index: z('slide-menu');

	display: flex;
	flex-direction: column;
	justify-content: center;

	width: 100%;
	max-width: px2rem(360px);
	height: 100%;
	padding: px2rem(50px) px2rem(65px) px2rem(50px);

	background-color: var(--ptf-color-19);

	transition: transform var(--ptf-transition-duration) var(--ptf-transition-easing);
	transform: translateX(-100%);
	overflow: auto;

	@include mq('xlarge', min) {
		left: px2rem(100px);
	}
	@include mq('small') {
		padding: px2rem(30px);
	}

	@media (min-width: 1920px) {
		left: px2rem(150px);
	}

	&.is-open {
		transform: translateX(0);
	}

	&__navigation {
		height: 100%;
		padding: px2rem(30px) 0;

		overflow-y: auto;
	}

}

/* SECTION: NAVIGATION
-------------------------------------------- */

.ptf-nav--slide {
	@extend %ptf_large_navigation;
}
