/* WIDGET: SHOWCASE 1
-------------------------------------------- */

.ptf-showcase-1 {
	position: relative;

	height: 100vh;
	min-height: px2rem(550px);

	.section {
		position: absolute;
		top: 0;
		left: 0;

		min-height: 100vh;
		padding: 0;

		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

		overflow-y: auto;
	}

	.ptf-showcase-item {
		display: flex;
		flex-direction: column;

		width: 100%;
		height: 100%;
		padding: 25vh 0 px2rem(80px);

		&__header {
			margin-bottom: px2rem(40px);
		}

		&__title {
			@include mq('xlarge', min) {
				font-size: px2rem(80px);
				line-height: 1;
			}

			@media (min-width: 1920px) {
				font-size: px2rem(100px);
				line-height: 1;
			}
		}

		&__category {
			margin-top: px2rem(100px);

			color: var(--ptf-color-black);
			font-size: px2rem(30px);
			line-height: 2;
			font-weight: 600;

			@include mq('medium') {
				margin-top: px2rem(50px);
			}

		}

		&__footer {
			max-width: px2rem(560px);
			margin-top: auto;

			font-size: px2rem(18px);
		}

	}

	section.dark {

		.ptf-showcase-item__title,
		.ptf-showcase-item__category {
			color: var(--ptf-color-white);
		}

	}

}

/* SECTION: FIXED FOOTER
-------------------------------------------- */

.ptf-showcase-fixed-footer--inner {
	position: fixed;
	right: 0;
	bottom: px2rem(80px);
	left: 0;
	z-index: 10;

	display: none;

	color: var(--ptf-color-black);

	opacity: 0;

	transition: opacity var(--ptf-transition-duration) var(--ptf-transition-easing);
	pointer-events: none;

	&.active {
		opacity: 1;
	}

	&.dark {
		color: var(--ptf-color-white);
	}

	.all {
		display: inline-flex;
		align-items: center;

		text-transform: uppercase;
		font-size: px2rem(18px);
		font-weight: 600;

		pointer-events: auto;

		svg {
			height: 1em;
			margin-left: px2rem(35px);

			font-size: px2rem(42px);

			transition: transform var(--ptf-transition-duration) var(--ptf-transition-easing);
		}

		&:hover {
			color: inherit;

			svg {
				transform: translateX(2px);
			}

		}

	}

	@media (min-width: 1920px) and (min-height: 1080px) {
		display: block;
	}

}

.ptf-showcase-fixed-footer--outer {
	@extend .ptf-showcase-fixed-footer--inner;

	.socials {
		display: flex;
		flex-direction: column;

		pointer-events: auto;

		a + a {
			margin-top: px2rem(14px);
			margin-left: 0 !important;
		}

	}

	&.dark .socials {

		a:not(:hover) {
			color: inherit;
		}

	}

	.copyright {
		text-align: right;
		text-transform: uppercase;
		font-size: px2rem(14px);

		pointer-events: auto;
	}

}
