@font-face {
 font-family: 'Cerebri Sans';
 font-weight: 300;
 font-style: normal;
 src: url('CerebriSans-Light.woff2') format('woff2');
}

@font-face {
 font-family: 'Cerebri Sans';
 font-weight: normal;
 font-style: normal;
 src: url('CerebriSans-Regular.woff2') format('woff2');
}

@font-face {
 font-family: 'Cerebri Sans';
 font-weight: 600;
 font-style: normal;
 src: url('CerebriSans-SemiBold.woff2') format('woff2');
}

@font-face {
 font-family: 'Cerebri Sans';
 font-weight: 700;
 font-style: normal;
 src: url('CerebriSans-Bold.woff2') format('woff2');
}

@font-face {
 font-family: 'Cerebri Sans';
 font-weight: 800;
 font-style: normal;
 src: url('CerebriSans-ExtraBold.woff2') format('woff2');
}